import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import {isTesting, MscreenWidth, screenWidth} from "../../App";
import Holidays from "date-holidays";
import {booking_title, donateForNochMall, recipient, recipient_id, tome} from "./insert_box_recipient";
import {boxNum, objectSize} from "./insert_box_package_dimensions";
import RestAPI from "../../RestAPI";
import Loading_spinner from "../common/loading_spinner";
import {location} from "./insert_box_location";


export var holidaysInBerlin = new Holidays('DE', 'BE', 'BE');
export var currentDate = new Date();
export var dateForReservation = new Date(currentDate.setDate(currentDate.getDate() + 2))
export var dateForEmpty = new Date(currentDate.setDate(currentDate.getDate() + 4));
export var trackingNumber;
export var pin;

function Insert_box_selection({switchToIndex, isAdminVar, isLoggedInVar}) {
    const [backBtnClicked, setBackBtnClicked] = useState(false);

    const handleBackBtn = (event) => {
        event.preventDefault();

        setBackBtnClicked(true);

    }
    useEffect(() =>{
        if (backBtnClicked){
            switchToIndex("Insert_box_recipient");
        }
    });


    const [restQueryIsLoading, setRestQueryIsLoading] = useState(false);
        setDayHours(dateForReservation)
        setDayHours(dateForEmpty)

    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        //isTesting ? switchToIndex("Insert_box_confirmation") : bookBox();
        //setErrors(validation(values, "Enter_login_data"));
        isTesting ?  setDataIsCorrect(true) : bookBox();
    }

    function convertDateForBackEnd(date: Date) {
        return date.getFullYear().toString() + ("0" + (date.getMonth() + 1)).slice(-2).toString() + ("0" + (date.getDate())).slice(-2).toString() + ("0" + (date.getHours())).slice(-2).toString() + ("0" + (date.getMinutes())).slice(-2).toString() + "00";
    }

    useEffect(() => {
        if (/*Object.keys(errors).length === 0 &&*/ dataIsCorrect) {
            isTesting ? switchToIndex("Insert_box_confirmation") : switchToIndex("Insert_box_confirmation");
        }
    }/*, [errors]*/);


    function setDayHours(day: Date) {
        if (checkIfDayIsASunday(day) || checkIfDayIsAHoliday(day)) {
            day.setHours(14)
            day.setMinutes(0,0,0)
        } else {
            day.setHours(20)
            day.setMinutes(0,0,0)
        }
    }

    function checkIfDayIsASunday(day: Date) {
        return day.getDay() === 0;
    }

    function checkIfDayIsAHoliday(day: Date) {
        const isHoliday = holidaysInBerlin.isHoliday(day);
        if (!isHoliday) {
            return false;
        } else {
            return isHoliday.filter(function (holiday) {
                return holiday.type === "public";
            }).length > 0
        }

    }

    function bookBox() {
        let data = {
                id_destination: tome ? 0 : donateForNochMall ? 1 : recipient_id,
                terminal_specific_box_decision: boxNum,
                t_start_avg: convertDateForBackEnd(dateForReservation),
                t_end_avg: convertDateForBackEnd(dateForEmpty),
                title : booking_title,
                object_size: objectSize,
                location : location
            }
            setRestQueryIsLoading(true);
        RestAPI("/api/post_book_locker", "POST", data).then((resp) => {
                // Error handling
                return resp.json()


            })
            .then((response) => {
                setRestQueryIsLoading(false);
                if (response["status"] === 200) {
                    setDataIsCorrect(true);
                    trackingNumber = response["tracking_Number"];
                    pin = response["pin"];
                    sessionStorage.removeItem("Booking");
                } else {

                }
            })
            .catch((err) => {
                // Error
            });
    }

    return (
        <main className="main">
            <Loading_spinner isRunning={restQueryIsLoading}/>
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <div className="info_box">
                <div className="insert_box_content">
                    {
                        MscreenWidth.matches ? <div><h2 className="untertitel_h2">Fachbuchung</h2></div> : null
                    }
                    <div className="insert_box_content_left">
                        <div style={MscreenWidth.matches ? {display:"none"} : null} className="insert_box_content_left_item">
                            <h2 className="untertitel_h2">Fachbuchung</h2>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">1</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {screenWidth.matches ?
                                <p style={{color:"#ffffff"}} className="fliesstext_p">Standort<br/>auswählen</p>
                                :
                                <h4 style={{color:"#ffffff", fontWeight:"bold"}} className="teaser_h4">Standort auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">2</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#fff"}} className="fliesstext_p">Fachgröße<br/>auswählen</p>
                                        :
                                    <h4 style={{color:"#fff", fontWeight:"bold"}} className="teaser_h4">Fachgröße auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">3</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Empfänger:in<br/>angeben</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Empfänger:in angeben</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div style={{backgroundColor: "#f61"}} className="insert_box_content_left_item_progress_bar_step">
                                <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">4</h4>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#f61"}} className="fliesstext_p">Bestätigen</p>
                                        :
                                    <h4 style={{color: "#f61", fontWeight:"bold"}} className="teaser_h4">Bestätigen</h4>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="insert_box_content_right">
                        <div className="insert_box_content_right_item">
                            <h2 className="zwischenueberschrift_h3">Fast fertig!</h2>
                        </div>
                        <div className="insert_box_content_right_item">
                            <h4 className="teaser_h4">Deine Buchung im Überblick:</h4>
                        </div>
                        <div className="insert_box_content_right_item insert_box_content_right_item_booking_data">
                            <div className="booking_data">
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Einlegen bis:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.getDate().toString() + "." + (dateForReservation.getMonth() + 1).toString() + "." + dateForReservation.getFullYear().toString()}</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForReservation.getHours().toString() + ":" + "00" + " Uhr"}</p>
                                </div>
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Abholen bis:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.getDate().toString() + "." + (dateForEmpty.getMonth() + 1).toString() + "." + dateForEmpty.getFullYear().toString()}</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{dateForEmpty.getHours().toString() + ":" + "00" + " Uhr"}</p>
                                </div>
                            </div>
                            <div className="booking_data">
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Fachgröße:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p">{isTesting ? "S" : objectSize}</p>
                                </div>
                                <div className="booking_data_item">
                                    <p className="fliesstext_p">Empfänger:in:</p>
                                    <p style={{color:"#a0a0a0"}} className="fliesstext_p" id={"recipientOption"}  className="fliesstext_p">{tome ? "An mich" : donateForNochMall ? "NochMall" : recipient}</p>
                                    {!isAdminVar&&
                                        <p className="fliesstext_p"><br/></p>
                                    }
                                </div>
                                {
                                    (isTesting || isAdminVar) &&
                                    <div className="booking_data_item">
                                        <p className="fliesstext_p">Standort:</p>
                                        <p style={{color:"#a0a0a0"}} className="fliesstext_p" id={"recipientOption"}  className="fliesstext_p">{location == "TH" ? "Kiez-Locker Tempelhof" : "Kiez-Locker Adlershof"}</p>
                                        <p className="fliesstext_p"><br/></p>
                                    </div>
                                }

                            </div>


                        </div>
                        <div style={{flex:1}}></div>
                        <div className="insert_box_btn_field">
                            <div className="btn_field_one_btn">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleBackBtn}>Zurück</button>
                            </div>
                            <div className="btn_field_one_btn">
                                <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Bestätigen</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Insert_box_selection;
