import React, {useEffect, useState} from 'react';
import './insert_box.css';
import '../../GlobalStyle.css'
import {Footer} from "../common";
import Navbar from "../common/Navbar/Navbar";
import Info_icon from "../../static/insert_box/Info_grau.svg"
import {isTesting, MscreenWidth, screenWidth} from "../../App";
import validation from "./validation";
import RestAPI from "../../RestAPI";
import Tippy from '@tippy.js/react';
import PopUpWindow from "./PopUpWindow";
import {location} from "./insert_box_location";
export var boxNum;
export var objectSize;


const ToolTip =
        <div className="tootip_content">
            <ol>
                <li className="tootip_content_p">Wähle ein Fach in der richtigen Größe</li>
                    <ul>
                        <li className="tootip_content_p">Ausmessen des Gegenstandes (Höhe, Länge, Breite)</li>
                        <li className="tootip_content_p">Wähle die für Deinen Artikel kleinstmögliche Fachgröße</li>
                        <li className="tootip_content_p">Sollte eine Fachgröße belegt sein, wird automatisch das nächstgrößere Fach gebucht</li>
                    </ul>
                <li className="tootip_content_p">in der "Terminal-Auslastung" siehst Du, welches Fach frei ist</li>
            </ol>
        </div>


function Insert_box_package_dimensions({switchToIndex, isLoggedInVar, isAdminVar}) {
    const [xsChecked, setXSChecked] = React.useState(false);
    const [sChecked, setSChecked] = React.useState(false);
    const [mChecked, setMChecked] = React.useState(false);
    const [lChecked, setLChecked] = React.useState(false);
    const [xlChecked, setXLChecked] = React.useState(false);
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        boxSize: ""
    })
    const [backBtnClicked, setBackBtnClicked] = useState(false);
    const handleBackBtn = (event) => {
        event.preventDefault();
        setBackBtnClicked(true);
    }
    useEffect(()=>{
    },[])
    useEffect(() =>{
        if (backBtnClicked){
            switchToIndex("Insert_box_location");
        }
    });
    function handleXSCheckBoxChange() {
        setXSChecked(!xsChecked);
        setSChecked(false);
        setMChecked(false);
        setLChecked(false);
        setXLChecked(false);
    }

    function handleSCheckBoxChange() {
        setSChecked(!sChecked);
        setXSChecked(false);
        setMChecked(false);
        setLChecked(false);
        setXLChecked(false);
    }

    function handleMCheckBoxChange() {
        setMChecked(!mChecked);
        setXSChecked(false);
        setSChecked(false);
        setLChecked(false);
        setXLChecked(false);
    }

    function handleLCheckBoxChange() {
        setLChecked(!lChecked);
        setXSChecked(false);
        setMChecked(false);
        setSChecked(false);
        setXLChecked(false);
    }

    function handleXLCheckBoxChange() {
        setXLChecked(!xlChecked);
        setXSChecked(false);
        setMChecked(false);
        setLChecked(false);
        setSChecked(false);
    }

    const updateSizeValue = (event) => {
        setValues({
            ...values,
            boxSize: event.target.value,
        })
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values, "Insert_box_package_dimensions", false, xsChecked, sChecked, mChecked, lChecked, xlChecked));
        isTesting ? setDataIsCorrect(true) : checkBoxAvailability();
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            isTesting ? switchToIndex("Insert_box_recipient") : switchToIndex("Insert_box_recipient");
        }
    }, [errors, dataIsCorrect]);

    function checkBoxAvailability() {

            let data =  {object_size: values.boxSize, location: location}
            RestAPI("/api/check_box_availability", "POST",data).then((resp) => {
                // Error handling
                return resp.json()
            })
            .then((response) => {
                if (response === -1) {
                    setErrors(validation(values, "Insert_box_package_dimensions", true, xsChecked, sChecked, mChecked, lChecked, xlChecked));
                } else {
                    boxNum = response;
                    objectSize = values.boxSize;
                    setDataIsCorrect(true);
                }

            })
            .catch((err) => {
                // Error
                setErrors(err);
            });
    }

    return (
        <main className="main">
            <Navbar admin={isAdminVar} logged={isLoggedInVar}/>
            <div className="info_box">
                {/*<PopUpWindow></PopUpWindow>*/}
                <div className="insert_box_content">
                    {
                        MscreenWidth.matches ? <div><h2 className="untertitel_h2">Fachbuchung</h2></div> : null
                    }

                    <div className="insert_box_content_left">
                        <div style={MscreenWidth.matches ? {display:"none"} : null} className="insert_box_content_left_item">
                            <h2 className="untertitel_h2">Fachbuchung</h2>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">1</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {screenWidth.matches ?
                                <p style={{color:"#ffffff"}} className="fliesstext_p">Standort<br/>auswählen</p>
                                :
                                <h4 style={{color:"#ffffff", fontWeight:"bold"}} className="teaser_h4">Standort auswählen</h4>
                                }

                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div style={{backgroundColor: "#f61"}}
                                 className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#fff", fontWeight:"bold"}} className="teaser_h4">2</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#f61"}} className="fliesstext_p">Fachgröße<br/>auswählen</p>
                                        :
                                    <h4 style={{color:"#f61", fontWeight:"bold"}} className="teaser_h4">Fachgröße auswählen</h4>
                                }
                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">3</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Empfänger:in<br/>angeben</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Empfänger:in angeben</h4>
                                }

                            </div>
                        </div>
                        <div className="insert_box_content_left_item">
                            <div className="insert_box_content_left_item_progress_bar_step">
                                <h2 style={{color: "#a0a0a0", fontWeight:"bold"}} className="teaser_h4">4</h2>
                            </div>
                            <div className="insert_box_content_left_item_progress_bar_text">
                                {
                                    screenWidth.matches ?
                                    <p style={{color:"#ffffff"}} className="fliesstext_p">Bestätigen</p>
                                        :
                                    <h4 style={{color: "#ffffff", fontWeight:"bold"}} className="teaser_h4">Bestätigen</h4>
                                }

                            </div>
                        </div>
                    </div>

                    {
                        location == "AH" ?
                            <div className="insert_box_content_right">
                                <div className="insert_box_content_right_item">
                                    <h2  className="zwischenueberschrift_h3">Fachgröße</h2>
                                     {/*
                                    <Tippy className="tool_tip" content={ToolTip}>
                                        <img style={{paddingBottom:"15px"}} width="24px" src={Info_icon}/>
                                    </Tippy>
                                    */}
                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">XL</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 style={{fontWeight:"normal"}} className="fliesstext_p">40 x 60 x 70 cm (Staubsauger)</h2>
                                    </div>

                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="xl" name="xl" value={"XL"} className="checkbox_input" type="checkbox"
                                                   checked={xlChecked} onChange={handleXLCheckBoxChange}
                                                   onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {(errors.checked || errors.responseMessage) &&
                                <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                                    <span className="error_span">{errors.checked ? errors.checked : errors.responseMessage}</span>
                                </div>
                                }
                                <div style={{display:"flex", flex:"1"}}></div>
                                <div style={{justifyContent: "space-between", width:"100%"}} className="insert_box_content_right_item">
                                    <div className="btn_field_one_btn">
                                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleBackBtn}>Zurück</button>
                                    </div>
                                    <div className="btn_field_one_btn">
                                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Weiter</button>
                                    </div>

                                </div>
                            </div>
                                    :
                            <div className="insert_box_content_right">
                                <div className="insert_box_content_right_item">
                                    <h2  className="zwischenueberschrift_h3">Fachgröße</h2>
                                    <Tippy className="tool_tip" content={ToolTip}>
                                        <img style={{paddingBottom:"15px"}} width="24px" src={Info_icon}/>
                                    </Tippy>

                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">XS</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 className="fliesstext_p">9 &nbsp;&nbsp;x 48 x 61 cm (Aktenordner)</h2>
                                    </div>
                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="xs" name="xs" value={"XS"} className="checkbox_input" type="checkbox"
                                                   checked={xsChecked} onChange={handleXSCheckBoxChange}
                                                   onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">S</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 style={{fontWeight:"normal"}} className="fliesstext_p">20 x 48 x 61 cm (Schuhkarton)</h2>
                                    </div>
                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="s" name="s" value={"S"} className="checkbox_input" type="checkbox"
                                                   checked={sChecked}
                                                   onChange={handleSCheckBoxChange} onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">M</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 style={{fontWeight:"normal"}} className="fliesstext_p">33 x 48 x 61 cm (Werkzeugkoffer)</h2>
                                    </div>
                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="m" name="m" value={"M"} className="checkbox_input" type="checkbox"
                                                   checked={mChecked}
                                                   onChange={handleMCheckBoxChange} onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">L</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 style={{fontWeight:"normal"}} className="fliesstext_p">43 x 48 x 61 cm (Kaffeemaschine)</h2>
                                    </div>
                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="l" name="l" value={"L"} className="checkbox_input" type="checkbox"
                                                   checked={lChecked}
                                                   onChange={handleLCheckBoxChange} onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="insert_box_content_right_item">
                                    <div className="package_dimensions_content_left_item">
                                        <h2 style={{color:"#FFFFFF"}} className="fliesstext_p">XL</h2>
                                    </div>
                                    <div className="package_dimensions_content_middle_item">
                                        <h2 style={{fontWeight:"normal"}} className="fliesstext_p">73 x 48 x 61 cm (Staubsauger)</h2>
                                    </div>
                                    <div className="package_dimensions_content_right_item">
                                        <label>
                                            <input id="xl" name="xl" value={"XL"} className="checkbox_input" type="checkbox"
                                                   checked={xlChecked} onChange={handleXLCheckBoxChange}
                                                   onClick={updateSizeValue}/>
                                            <span className="checkbox">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {(errors.checked || errors.responseMessage) &&
                                <div style={{justifyContent: "flex-start"}} className="insert_box_content_right_item">
                                    <span className="error_span">{errors.checked ? errors.checked : errors.responseMessage}</span>
                                </div>
                                }
                                <div style={{display:"flex", flex:"1"}}></div>
                                <div style={{justifyContent: "space-between", width:"100%"}} className="insert_box_content_right_item">
                                    <div className="btn_field_one_btn">
                                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#a0a0a0"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleBackBtn}>Zurück</button>
                                    </div>
                                    <div className="btn_field_one_btn">
                                        <button style={{"--btn_font_color":"#ffffff", "--btn_background_color":"#f61"}} id="loginbtn" type="button" className="ueberschrift_btn" onClick={handleFormSubmit}>Weiter</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default Insert_box_package_dimensions;
